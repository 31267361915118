import { useState, useEffect } from "react";
import { Facebook, Globe, Instagram, Mail } from "lucide-react";

export default function ConstructionPage() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    const difference = +new Date("2025-08-15") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        jours: Math.floor(difference / (1000 * 60 * 60 * 24)),
        heures: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        secondes: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center gradient-background text-white">
      <div className="w-full bg-yellow-500 text-black py-4">
        <marquee>
          <a
            href="https://forms.gle/K7wzvdgTorLnUxPr9"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white font-bold text-lg"
          >
            Cliquez ici et remplissez le formulaire pour devenir bénévole aux
            RJV 2025 !
          </a>
        </marquee>
      </div>
      <img
        src="/supernana.png"
        alt="super nana"
        className="absolute left-0 top-1/2 transform -translate-y-1/2 h-40 w-auto object-contain"
      />

      <img
        src="/edd.png"
        alt="edd"
        className="absolute right-0 top-1/2 transform -translate-y-1/2 h-40 w-auto object-contain"
      />

      <div className="mt-8">
        <img
          src="/pdt.png?height=200&width=200"
          alt="RJV Savièse Logo"
          className="mx-auto h-40 w-auto object-contain"
        />
      </div>

      <p className="text-7xl mb-10 text-white-600">Coming Soon</p>

      <div className="flex flex-wrap justify-center gap-4 mb-8">
        {Object.entries(timeLeft).map(([unit, value]) => (
          <div key={unit} className="flex flex-col items-center">
            <div className="w-32 h-32 flex flex-col items-center justify-center bg-white rounded-full border-4 shadow-lg transform hover:scale-110 transition-transform duration-200">
              <span className="text-3xl md:text-4xl font-bold text-orange-600">
                {value}
              </span>
              <span className="text-sm md:text-base text-black font-semibold mt-1">
                {unit}
              </span>
            </div>
          </div>
        ))}
      </div>

      <p className="text-4xl mb-4 text-white-600">Du 15 au 17 août 2025</p>

      <p className="text-xl md:text-2xl mb-8 text-white-600">
        Notre site est actuellement en construction.
      </p>

      <div className="flex justify-center space-x-4 mb-8">
        <SocialBubble
          href="https://jeunessesaviese.ch"
          icon={<Globe size={24} />}
          label="Jeunesse de Savièse"
          bgColor="bg-orange-500"
        />
        <SocialBubble
          href="https://www.instagram.com/rjv2025_saviese/"
          icon={<Instagram size={24} />}
          label="Instagram"
          bgColor="bg-pink-600"
        />
        <SocialBubble
          href="https://www.facebook.com/profile.php?id=100067419638062"
          icon={<Facebook size={24} />}
          label="Facebook"
          bgColor="bg-blue-500"
        />
        <SocialBubble
          href="mailto:rjv2025saviese@gmail.com"
          icon={<Mail size={24} />}
          label="Contact"
          bgColor="bg-red-500"
        />
      </div>
    </div>
  );
}

function SocialBubble({ href, icon, label, bgColor, hoverColor }) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={`group relative ${bgColor} hover:${hoverColor} text-grey-600 hover:text-white w-12 h-12 rounded-full flex items-center justify-center transition duration-300`}
      aria-label={label}
    >
      {icon}
      <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-white text-gray-800 px-2 py-1 rounded text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap">
        {label}
      </span>
    </a>
  );
}
